<!-- src/components/Toast.vue -->
<template>
  <ToastProvider>
    <ToastViewport
      class="fixed bottom-0 right-0 z-50 m-0 flex w-[390px] max-w-[100vw] list-none flex-col gap-2 p-6 outline-none"
    />
    <ToastRoot
      v-model:open="isOpen"
      :class="[
        'rounded-lg bg-white p-4 opacity-90 shadow-lg',
        'radix-state-open:animate-slideIn',
        'radix-state-closed:animate-hide',
        'radix-swipe-end:animate-swipeOut',
        'translate-x-radix-toast-swipe-move-x',
        'radix-swipe-cancel:translate-x-0 radix-swipe-cancel:duration-200 radix-swipe-cancel:ease-[ease]',
      ]"
      :duration="duration"
      :actionHandler="actionHandler"
    >
      <div class="flex items-center justify-between">
        <ToastDescription class="text-sm text-gray-700">
          {{ text }}
        </ToastDescription>
        <ToastClose class="text-gray-400 hover:text-gray-600" @click="close">
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
              fill="currentColor"
              fill-rule="evenodd"
              clip-rule="evenodd"
            ></path>
          </svg>
        </ToastClose>
      </div>
      <button
        v-if="actionLabel"
        @click="action"
        class="mt-2 text-sm font-medium text-blue-600 hover:text-blue-800"
      >
        {{ actionLabel }}
      </button>
    </ToastRoot>
  </ToastProvider>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import {
  ToastProvider,
  ToastRoot,
  ToastViewport,
  ToastClose,
  ToastDescription,
} from 'radix-vue';

const props = defineProps<{
  text: string;
  actionLabel?: string;
  duration?: number;
  visible: boolean;
  actionHandler?: () => void;
}>();

const emit = defineEmits<{
  (e: 'action'): void;
  (e: 'close'): void;
}>();

const isOpen = ref(false);

watch(
  () => props.visible,
  (newValue) => {
    isOpen.value = newValue;
  }
);

const close = () => {
  isOpen.value = false;
  emit('close');
};

const action = () => {
  if (props.actionHandler) {
    props.actionHandler();
  }
  emit('action');
  close();
};
</script>
