<template>
  <Teleport to="body">
    <div
      v-if="isVisible"
      ref="tooltipRef"
      class="absolute z-50 max-w-xs rounded-lg bg-blue-600 p-5 text-white shadow-lg"
      :style="positionStyle"
    >
      <button
        @click="close"
        class="absolute right-2 top-2 text-white transition-colors duration-200 hover:text-blue-200"
        aria-label="Close"
        id="tool-tip-close"
      >
        <XMarkIcon class="h-4 w-4" />
      </button>
      <p class="text-xs font-bold mb-3">{{ title }}</p>
      <p class="text-xs">{{ text }}</p>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { useClickOutside } from '@/composables/useClickOutside';
import { useTooltipPosition } from '@/composables/useTooltipPosition';
import { ref } from 'vue';
type Position = 'top' | 'bottom' | 'left' | 'right' | 'bottom-left'

const isVisible = ref(false);
const targetId = ref('');
const text = ref('');
const title = ref('');
const tooltipRef = ref<HTMLElement | null>(null);
const tipPosition = ref<Position>('bottom-left');

const { positionStyle } = useTooltipPosition(
  tooltipRef,
  targetId,
  tipPosition
);

const open = (id: string, description: string, head: string, position?: Position) => {
  const getTooltipStatusFromStorage = localStorage.getItem(id);
  if (getTooltipStatusFromStorage === 'closed') {
    return;
  } else {
    localStorage.setItem(id, 'open');
    targetId.value = id;
    text.value = description;
    title.value = head;
    isVisible.value = true;
    console.log(position)
    if (position) tipPosition.value = position;
  }
};

const close = () => {
  isVisible.value = false;
  if (targetId.value) {
    localStorage.setItem(targetId.value, 'closed');
  }
};

useClickOutside(tooltipRef, close);

defineExpose({ open, close });
</script>
