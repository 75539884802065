import { createRouter, createWebHistory } from "vue-router";
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { stytch } from "@/stytch";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("../views/LoginView.vue")
    },
    {
      path: "/add-email",
      name: "Add Email",
      component: () => import("../views/AddEmail.vue")
    },
    {
      path: "/verify/:token_id",
      name: "Verify Token",
      component: () => import("../views/authentication/VerifyToken.vue"),
      props: route => ({
        slug: route.query.slug,
        stytch_token_type: route.query.stytch_token_type,
        token: route.query.token
      })
    },
    {
      path: "/pdfview",
      name: "PDF Viewer",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/PDFView.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/editor",
      name: "Editor Viewer",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/NewEditorView/NewEditorView.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/project-details",
      name: "Project Detail Viewer",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/ProjectDetailView.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/saveToLibrary",
      name: "Save to Library",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/NewEditorView/SaveToLibraryView.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/product-type-details",
      name: "Product Type Details",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/ProductTypeDetails.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/project-configurator",
      name: "Project Configurator",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/ProjectConfigurator.vue"),
    },
    { 
      path: "/product-line-details",
      name: "Product Line Details",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/ProductLineDetails.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/comply",
      name: "Comply Viewer",
      // -- deprecated; currently used for customer support --
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/ComplyView.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/projects",
      alias: ["/"],
      name: "Project Viewer",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/ProjectsView.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/projects/:projectId",
      name: "Project Details",
      component: () => import("../views/ProjectDetailsView.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/library",
      name: "Library Viewer",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/LibraryView.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/shared-projects",
      name: "Shared Projects Viewer",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/SharedProjects.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/tags",
      name: "Tags Viewer",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/TagsView.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/product-lines",
      alias: ["/"],
      name: "Product Lines",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/ProductLine.vue"),
    },
    {
      path: "/product-types",
      alias: ["/"],
      name: "Product Types",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/ProductType.vue"),
    },
    {
      path: "/specifier",
      name: "Specifier Viewer",
      component: () => import("../views/SpecifierView.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/profile",
      name: "Profile Viewer",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/ProfileView.vue"),
      meta: { "requiresAuth": true }
    },
    {
      path: "/authenticate",
      name: "Authentication Viewer",
      component: () => import("../views/authentication/AuthenticationView.vue"),
    },
    {
      path: "/discoverOrganization",
      name: "Discover Organization Viewer",
      component: () => import("../views/authentication/OrgDiscoveryView.vue"),
    },
  ],
});


// Define a navigation guard to check if the user is logged in
router.beforeEach((to, from, next) => {
  const authToken = Cookies.get('auth_token');
  let stytchSession = null;
  try {
    stytchSession = stytch.session.getSync();
  } catch (error) {
    console.log(error);
  }
  if (to.matched.some(route => route.meta.requiresAuth) && !authToken && !stytchSession) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else {
    if (authToken && authToken !== 'undefined') {
      const tokenData = jwtDecode(authToken);
      const sessionData = {
        userId: tokenData.userId,
        orgId: tokenData.orgId,
        email: tokenData.email,
        orgName: tokenData.orgName,
        roleType: tokenData.roleType,
        isAIPoweredSuggestionsEnabled: tokenData.isAIPoweredSuggestionsEnabled,
        isProductsEnabled: tokenData.isProductsEnabled,
        isRequestProEnabled: tokenData.isRequestProEnabled,
        username: tokenData.sub
      };

      Object.entries(sessionData).forEach(([key, value]) => {
        sessionStorage.setItem(key, value);
      });
    }
    next();
  }
});

export default router;
