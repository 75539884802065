import axios from 'axios';

const BASEURL = process.env.NODE_ENV === 'production' 
  ? 'https://api.sonicspec.com' 
  : 'http://127.0.0.1:5000';

axios.defaults.baseURL = BASEURL;

axios.interceptors.request.use((config) => {
  const token = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
