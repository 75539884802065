<template>
  <div>
    <div class="dropdown-container">
      <button
        @click="toggleDropdown"
        class="rounded-b-0 btn-primary truncate rounded-t-sm text-xs"
      >
        Feedback
      </button>
      <div v-if="isOpen" class="dropdown-content bg-notionGray text-sm">
        <a target="_blank" href="https://dynnxzme1vx.typeform.com/to/poR9wjjW">
          Feedback Survey
        </a>
        <a target="_blank" href="https://dynnxzme1vx.typeform.com/to/m5XnCaQy">
          Report an Issue
        </a>
        <a
          target="_blank"
          href="mailto:phat@sonicspec.com,mike@sonicspec.com?subject=[Feedback]%20Request Feature"
        >
          Request a Feature
        </a>
        <a target="_blank" href="https://sonicspec.productlane.com/roadmap">
          Feedback on Roadmap
        </a>
      </div>
    </div>
    <div v-if="isOpen" class="overlay" @click="toggleDropdown"></div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const isOpen = ref(false);

    function toggleDropdown() {
      isOpen.value = !isOpen.value;
    }

    return { isOpen, toggleDropdown };
  },
};
</script>

<style scoped>
.dropdown-container {
  position: fixed;
  z-index: 9999;
  /* Adjust position on the page */
  top: 0;
  left: 5px;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9998;
  display: block;
}
</style>
